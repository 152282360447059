import { baseUrl, headers } from "../../constants";


const getCall = (url, body, successCb) => {
    return dispatch => {
        fetch(url, {
            method: "POST",
            body: body ? JSON.stringify(body) : null,
            headers
        })
        .then(res => res.json())
        .then(res => {
            dispatch(successCb(res.data));
        })
        .catch(error => {
            console.error(error);
        })
    }
};

/** Department */
export const setDDDeptAction = (payload) => {
    return {
        type: "SET_DD_DEPT",
        payload
    }
};
export const getDDDeptCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/department/list`, 
        body, 
        setDDDeptAction
    );
};

/** Area */
export const setDDAreaAction = (payload) => {
    return {
        type: "SET_DD_AREA",
        payload
    }
};
export const getDDAreaCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/area/list`, body, setDDAreaAction);
};

/** FACILITY */
export const setDDFacilityAction = (payload) => {
    return {
        type: "SET_DD_FACILITY",
        payload
    }
};
export const getDDFacilityCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/facility/list`, body, setDDFacilityAction);
};

/** equipment */
export const setDDEquAction = (payload) => {
    return {
        type: "SET_DD_EQU",
        payload
    }
};
export const getDDEquCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/equipment/list`, body, setDDEquAction);
};

/** Sub-equipment */
export const setDDSubEquAction = (payload) => {
    return {
        type: "SET_DD_SUB_EQU",
        payload
    }
};
export const getDDSubEquCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/sub_equipment/list`, body, setDDSubEquAction);
};

/** Device */
export const setDDDeviceAction = (payload) => {
    return {
        type: "SET_DD_DEVICE",
        payload
    }
};
export const getDDDeviceCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/device/list`, body, setDDDeviceAction);
};

/** employee */
export const setDDEmployeeAction = (payload) => {
    return {
        type: "SET_DD_EMP",
        payload
    }
};
export const getDDEmployeeCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/employee/list`, body, setDDEmployeeAction);
};

/** JobNature */
export const setDDJobNatureAction = (payload) => {
    return {
        type: "SET_DD_JOB_N",
        payload
    }
};
export const getDDJobNatureCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/job_nature/list`, body, setDDJobNatureAction);
};

/** JobNature */
export const setDDJobProAction = (payload) => {
    return {
        type: "SET_DD_JOB_P",
        payload
    }
};
export const getDDJobProCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/job_priority/list`, body, setDDJobProAction);
};

/** Work Permit */
export const setDDWorkAction = (payload) => {
    return {
        type: "SET_DD_WORK_PERMIT",
        payload
    }
};
export const getDDWorkCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/work_permit/list`, body, setDDWorkAction);
};

/** Vendor */
export const setDDVendorAction = (payload) => {
    return {
        type: "SET_DD_VENDOR",
        payload
    }
};
export const getDDVendorCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/vendor/list`, body, setDDVendorAction);
};

/** Task */
export const setDDTaskAction = (payload) => {
    return {
        type: "SET_DD_TASK",
        payload
    }
};
export const getDDTaskCall = (body) => {
    return getCall(
        `${baseUrl}/api/mms/task/list`, body, setDDTaskAction);
};
