import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { styled } from '@mui/system';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Typography } from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch } from "react-redux";

import { setFullLoad, setSnackMsg } from "../../redux/actions/common";


export default function Forms({ handleClose, formDataObj }) {
  const dispatch = useDispatch();
  const [age, setAge] = React.useState('');
  const isEdit = formDataObj?.formType === 'edit';
  const isView = formDataObj?.formType === 'view';
  const viewStyle = { pointerEvents: isView ? "none" : false };


  const [formData, setFormData] = useState(formDataObj);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };
  const handleSubmitForm = () => {
    console.log(formData);
    dispatch(setFullLoad(true));
     setTimeout(() => {
      dispatch(setFullLoad(false));
      dispatch(setSnackMsg({
        open: true, msg: 'Submitted Successfully'
      }));
      handleClose();
    }, 1500);
  }
  const handleClearForm = () => {
    setFormData({
      slno: '',
      dateofpurchase: null,
      make: '',
      poNumber: '',
      invoiceNumber: '',
      date: '',
      warrentystart: '',
      warrentyend: '',
      warrentyPeriod: '',
      expiryNotification: '',
      vendorSupplier: '',
      contactName: '',
      contactNumber: '',
      contactEmail: '',
      dateinvoice: null,
      dateinvoicenum: null,
    });
  };



  const [fields, setFields] = useState([{ value: '' }]);

  const handleAddField = () => {
    setFields([...fields, { value: '' }]);
  };

  const handleChangeAdd = (index, event) => {
    const newFields = [...fields];
    newFields[index].value = event.target.value;
    setFields(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form data:', fields);
  };


  return (
    <Box className="w-full mms-waranty-form">
      <Grid className='bg-slate-100 '>
        <form>
          <div className='text-left bg-white  m-2 border-solid border rounded'>

            <div className="mms-date-field p-3">
            <Grid xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} md={3}>
                    <TextField size='small' label="PO Number" variant="outlined"
                      name="poNumber"
                      sx={{width:'100%'}}
                      value={formData.poNumber}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <LocalizationProvider label="Date" dateAdapter={AdapterDayjs} >
                      <DatePicker
                      sx={{width:'100%'}}
                        value={formData.dateinvoicenum}
                        onChange={(date) => handleDateChange(date, 'dateinvoicenum')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                  <TextField size='small' label="Invoice Number" variant="outlined" name="invoiceNumber"
                      value={formData.invoiceNumber} sx={{width:'100%'}}
                      onChange={handleInputChange} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <LocalizationProvider label="Date" dateAdapter={AdapterDayjs} className="mms-date-field" sx={{width:'100%'}}>
                      <DatePicker
                        value={formData.dateofpurchase}
                        onChange={(date) => handleDateChange(date, 'dateofpurchase')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
              </Grid>
              <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={3}>
              <TextField size='small' label="Warrenty  Start" variant="outlined" name="warrentystart"
                      value={formData.warrentystart} sx={{width:'100%'}}
                      onChange={handleInputChange} />
              </Grid>
              <Grid item xs={12} md={2}>
              <TextField size='small' label="Warrenty  End" variant="outlined" name="warrentyend"
                      value={formData.warrentyend} sx={{width:'100%'}}
                      onChange={handleInputChange} />
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField size='small' label=" Warrenty Period" variant="outlined"
                      name="warrentyPeriod" sx={{width:'100%'}}
                      value={formData.warrentyPeriod}
                      onChange={handleInputChange} />
                </Grid>
                </Grid>
                <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={3}>
              <Grid item xs={12} sm={12}>
                    <TextField size='small' label="Expiry Notification" variant="outlined"
                      name="expiryNotification"  sx={{width:'100%'}}
                      value={formData.expiryNotification}
                      onChange={handleInputChange} />
                  </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={12}>
              <div >
              <form onSubmit={handleSubmit}>
                {fields.map((field, index) => (
                  <div key={index} className=''>

                    <Grid container spacing={2} alignItems="center" className=' p-3 '>

                      <Grid item xs={2} sm={1}>
                        <TextField size='small' label="S.No" variant="outlined"
                          name="sno"
                          value={field.value}
                          onChange={(e) => handleChangeAdd(index, e)} />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField size='small' label="Product / Material Name" variant="outlined"
                          name="productmaterialname"
                          value={field.value}
                          onChange={(e) => handleChangeAdd(index, e)} />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField size='small' label="Model/P.No" variant="outlined"
                          name="modelpno"
                          value={field.value}
                          onChange={(e) => handleChangeAdd(index, e)} />
                      </Grid>


                      <Grid item xs={2} sm={1}>
                        <TextField size='small' label="Make" variant="outlined"
                          name="make"
                          value={formData.make}
                          onChange={handleInputChange} />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField size='small' label="Value" variant="outlined"
                          name="value"
                          value={field.value}
                          onChange={(e) => handleChangeAdd(index, e)} />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField size='small' label="Remarks" variant="outlined"
                          name="remarks"
                          value={field.value}
                          onChange={(e) => handleChangeAdd(index, e)} />
                      </Grid>

                      <AddCircleIcon onClick={handleAddField} style={{ color: "green" }} />
                      <RemoveCircleIcon onClick={() => handleRemoveField(index)} style={{ color: "red" }}></RemoveCircleIcon>
                    </Grid>
                  </div>
                ))}

                <Button variant="contained" type="submit">Submit</Button>
              </form>

            </div>
              </Grid>
              </Grid>
              <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={3}>
              <TextField size='small' label="Vendor/Supplier" variant="outlined" name="vendorSupplier"
                      value={formData.vendorSupplier} sx={{width:'100%'}}
                      onChange={handleInputChange} />
              </Grid>
              </Grid>
              <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={3}>
              <TextField size='small' label="Contact Name " variant="outlined"
                      name="contactName" sx={{width:'100%'}}
                      value={formData.contactName}
                      onChange={handleInputChange} />
              </Grid>
              </Grid>
              <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={3}>
              <TextField size='small' label=" Contact number" variant="outlined"
                      name="contactNumber" sx={{width:'100%'}}
                      value={formData.contactNumber}
                      onChange={handleInputChange} />
              </Grid>
              </Grid>
              <Grid item xs={12} md={12} container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12} md={3}>
              <TextField size='small' label="Contact E-mail" variant="outlined"
                      name="contactEmail" sx={{width:'100%'}}
                      value={formData.contactEmail}
                      onChange={handleInputChange} />
              </Grid>
              </Grid>
              </Grid>

             


            </div>
          </div>

          <div className='mt-5 flex justify-center'>
            <div className=''>
              <Button variant="contained" color="success" onClick={handleSubmitForm}>Submit</Button>
            </div>
            <div className='mx-1'>
              <Button variant="contained" className='mx-1.5' >
                Add Similar
              </Button>
            </div>
            <div>
              <Button variant="contained" className='bg-amber-500' onClick={handleClearForm}>
                Clear
              </Button>
            </div>

          </div>
        </form>
      </Grid>
    </Box>
  );
}