import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';

export default function Settings() {
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const headerProps = {
    open, handleDrawerOpen
  }
  const menuProps = {
    setOpen, open
  }
  const tableProps = {
    setOpenDialog
  }
  const [formData, setFormData] = useState({
    slno: '',
    dateofpurchase: null,

  });
  const [fields, setFields] = useState([{ value: '' }]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date
    });
  };
  const handleSubmitForm = () => {
    console.log(formData);
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <Box sx={{ display: 'flex' }}>

      <HeaderMMS {...headerProps} />
      <MenuMMS {...menuProps} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} className='bg-slate-100'>
        <Grid className='bg-slate-100 '>
          <DrawerHeader />
          {/* <Forms /> */}
          <div className='rounded p-3 text-lg text-left font-semibold'>
            <h2>Reports</h2>
          </div>

          <div className="mms-report-sec">

            <div className='text-left bg-white  m-2 border-solid border rounded'>
            <h1 className='font-bold text-center'>Warrenty Reports</h1>
              <div className="mms-date-field">
                <Grid container xs={12} sm={12} className='items-start'>
                  <Grid item xs={12} md={12} container>



                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="PO Number" variant="outlined"
                        name="poNumber"
                        value={formData.poNumber}
                        className='w-full'
                        onChange={handleInputChange} />
                    </Grid>



                    <Grid item xs={12} sm={3} className='p-3'>
                      <LocalizationProvider label="Date" dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="PO Date"
                          value={formData.dateinvoicenum}
                          className='w-full'
                          onChange={(date) => handleDateChange(date, 'dateinvoicenum')}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>





                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Invoice Number" variant="outlined" name="invoiceNumber"
                        value={formData.invoiceNumber}
                        className='w-full'
                        onChange={handleInputChange} />
                    </Grid>




                    <Grid item xs={12} sm={3} className='p-3 '>
                      <LocalizationProvider label="Date" dateAdapter={AdapterDayjs} className="mms-date-field">
                        <DatePicker
                          label="Invoice Date"
                          value={formData.dateofpurchase}
                          className='w-full'
                          onChange={(date) => handleDateChange(date, 'dateofpurchase')}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>


                    </Grid>






                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Warrenty  Start" variant="outlined" name="warrentystart"
                        value={formData.warrentystart}
                        className='w-full'
                        onChange={handleInputChange} />

                    </Grid>


                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Warrenty  End" variant="outlined" name="warrentyend"
                        value={formData.warrentyend}
                        className='w-full'
                        onChange={handleInputChange} />
                    </Grid>






                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label=" Warrenty Period" variant="outlined"
                        name="warrentyPeriod"
                        className='w-full'
                        value={formData.warrentyPeriod}
                        onChange={handleInputChange} />
                    </Grid>


                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Expiry Notification" variant="outlined"
                        name="expiryNotification"
                        className='w-full'
                        value={formData.expiryNotification}
                        onChange={handleInputChange} />

                    </Grid>




                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Vendor/Supplier" variant="outlined" name="vendorSupplier"
                        value={formData.vendorSupplier}
                        className='w-full'
                        onChange={handleInputChange} />
                    </Grid>




                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Contact Name " variant="outlined"
                        name="contactName"
                        value={formData.contactName}
                        className='w-full'
                        onChange={handleInputChange} />
                    </Grid>





                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label=" Contact number" variant="outlined"
                        name="contactNumber"
                        value={formData.contactNumber}
                        className='w-full'
                        onChange={handleInputChange} />
                    </Grid>



                    <Grid item xs={12} sm={3} className='p-3 '>
                      <TextField size='small' label="Contact E-mail" variant="outlined"
                        name="contactEmail"
                        value={formData.contactEmail}
                        className='w-full'
                        onChange={handleInputChange} />

                    </Grid>
                    <Grid item xs={12} sm={10} className='p-3 '>
                      <button class="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded inline-flex items-center">Submit</button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} container>
                  <Grid item xs={12} sm={12} className='p-3 '>
                    <div class="space-x-4 ml-auto float-right">

                      <button class="bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                        <PictureAsPdfIcon></PictureAsPdfIcon>

                        PDF
                      </button>


                      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 18L22 12m0 0l-6-6m6 6H2" />
                        </svg>
                        CSV
                      </button>


                      <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16m-7 4h7" />
                        </svg>
                        Excel
                      </button>
                    </div>
</Grid>
                    <div className='mms-report-table-cnt'>
                     

                      <div className='mms-report-table-report'>

                      </div>
                      <div className='mms-report-table-view'>
                        <table className='w-full'>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Date of Purchase</th>
                              <th>Make</th>
                              <th>PO Number</th>
                              <th>Invoice Number</th>
                              <th>Date</th>
                              <th>Warrenty Start</th>
                              <th>Warrent End</th>
                              <th>Warrent Period</th>
                              <th>Expiry Notification</th>
                              <th>Vendor</th>
                              <th>Contact Name</th>
                              <th>Contact Number</th>
                              <th>Contact Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>01</td>
                              <td>22-Jun-2022</td>
                              <td>Order</td>
                              <td>12400</td>
                              <td>129832303</td>
                              <td>12-Jun-2022</td>
                              <td>12-Jun-2022</td>
                              <td>12-Jun-2032</td>
                              <td>10 Year</td>
                              <td>Testing</td>
                              <td>MMS</td>
                              <td>Sundhar</td>
                              <td>9943758612</td>
                              <td>sundhar@gmail.com</td>
                            </tr>
                            <tr>
                              <td>02</td>
                              <td>05-Jun-2022</td>
                              <td>Test</td>
                              <td>0932</td>
                              <td>73203</td>
                              <td>13-Jul-2022</td>
                              <td>13-Jul-2022</td>
                              <td>13-Jul-2032</td>
                              <td>5 Year</td>
                              <td>Testing</td>
                              <td>Amazon</td>
                              <td>Ram</td>
                              <td>3222233323</td>
                              <td>tam@gmail.com</td>
                            </tr>
                            <tr>
                              <td>03</td>
                              <td>04-Oct-2022</td>
                              <td>Making</td>
                              <td>3322</td>
                              <td>9332</td>
                              <td>04-Oct-2022</td>
                              <td>04-Oct-2022</td>
                              <td>04-Oct-2022</td>
                              <td>12 Year</td>
                              <td>Testing</td>
                              <td>Quickbag</td>
                              <td>Shafi</td>
                              <td>432299</td>
                              <td>shafi@gmail.com</td>
                            </tr>
                            <tr>
                              <td>04</td>
                              <td>14-Oct-2022</td>
                              <td>Order</td>
                              <td>4433</td>
                              <td>3222</td>
                              <td>14-Oct-2022</td>
                              <td>14-Oct-2022</td>
                              <td>14-Oct-2025</td>
                              <td>3 Year</td>
                              <td>Testing</td>
                              <td>LLC</td>
                              <td>Richard</td>
                              <td>322443032</td>
                              <td>richard@gmail.com</td>
                            </tr>
                            <tr>
                              <td>05</td>
                              <td>15-Nov-2022</td>
                              <td>Order</td>
                              <td>12400</td>
                              <td>129832303</td>
                              <td>15-Nov-2022</td>
                              <td>15-Nov-2022</td>
                              <td>15-Nov-2032</td>
                              <td>10 Year</td>
                              <td>Testing</td>
                              <td>swd</td>
                              <td>Rahim</td>
                              <td>9943758612</td>
                              <td>Rahim@gmail.com</td>
                            </tr>
                            <tr>
                              <td>06</td>
                              <td>22-Jun-2022</td>
                              <td>Order</td>
                              <td>12400</td>
                              <td>129832303</td>
                              <td>12-Jun-2022</td>
                              <td>12-Jun-2022</td>
                              <td>12-Jun-2032</td>
                              <td>10 Year</td>
                              <td>Testing</td>
                              <td>MMS</td>
                              <td>Sundhar</td>
                              <td>9943758612</td>
                              <td>sundhar@gmail.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Grid>
                </Grid>

              </div>
            </div>
          </div>






        </Grid>
      </Box>
    </Box>
  );

}