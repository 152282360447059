
import { baseUrl } from "../../constants";
import { getDDDeptCall } from "../../redux/actions/dropDown";
import { setFullLoad } from "../../redux/actions/common";

export default {
    "formTitle": "Add Area",
    "tableTitle": "Area List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(setFullLoad(true));
            const dropDownArr = [
                getDDDeptCall,
            ];
            setTimeout(()=>{
                dispatch(setFullLoad(false));
            }, 500);
            dropDownArr.forEach((func,) => {
                    dispatch(func());
            });
            // dropDownArr.forEach((func, index) => {
            //     setTimeout(()=>{
            //         dispatch(func());
            //     }, index*700);
            // });
        },
        list: {
            url: `${baseUrl}/api/mms/area/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/area`,
        edit: `${baseUrl}/api/mms/area/edit`,
        delete: `${baseUrl}/api/mms/area/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.department_id,
            sortable: true
        },
        {
            name: 'Area',
            selector: row => row.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'text',
                    label: 'Area',
                    name: 'name',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": 1,
        "area": "dgdg"
    }]
}