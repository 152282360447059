
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Department",
    "tableTitle": "Department List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/mms/department/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/department`,
        edit: `${baseUrl}/api/mms/department/edit`,
        delete: `${baseUrl}/api/mms/department/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Department',
                    name: 'name',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": "dgdg"
    }]
}