
import { baseUrl } from "../../constants";

export default {
    "formTitle": "Add Task",
    "tableTitle": "Task List",
    "apiDetails": {
        startUp: () => {
        },
        list: {
            url: `${baseUrl}/api/mms/task/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/task`,
        edit: `${baseUrl}/api/mms/task/edit`,
        delete: `${baseUrl}/api/mms/task/remove`
    },
    "tableCol": [
        {
            name: 'Task No',
            selector: row => row.taskNo,
            sortable: true
        },
        {
            name: 'Task Date',
            selector: row => row.taskDate,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Task No',
                    name: 'taskNo',
                    "required": true
                },
                {
                    type: 'date',
                    label: 'Task Date',
                    name: 'taskDate',
                    "required": true
                },
                {
                    type: 'text',
                    label: 'Action',
                    name: 'action',
                    "required": true
                },
                {
                    type: 'select',
                    label: 'Status',
                    name: 'status',
                    "options": [
                        "Open",
                        "In-Progress",
                        "Closed"
                    ],
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "taskNo": "12",
        "taskDate": "12-01-2024",
        "status": 1,
        "action": "dgdg"
    }]
}