
import { baseUrl } from "../../constants";
import { getDDDeptCall, getDDAreaCall, 
    getDDFacilityCall, getDDEquCall } from "../../redux/actions/dropDown";
import { setFullLoad } from "../../redux/actions/common";

export default {
    "formTitle": " Sub Equipment",
    "tableTitle": "Sub Equipment List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(setFullLoad(true));
            const dropDownArr = [
                getDDDeptCall, getDDAreaCall, 
                getDDFacilityCall, getDDEquCall
            ];
            setTimeout(()=>{
                dispatch(setFullLoad(false));
            }, 500);
            dropDownArr.forEach((func,) => {
                    dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/mms/sub_equipment/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/sub_equipment`,
        edit: `${baseUrl}/api/mms/sub_equipment/edit`,
        delete: `${baseUrl}/api/mms/sub_equipment/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.department_id,
            sortable: true
        },
        {
            name: 'Area',
            selector: row => row.area_id,
            sortable: true
        },
        {
            name: 'Facility',
            selector: row => row.facility_id,
            sortable: true
        },
        {
            name: 'Equipment',
            selector: row => row.equipment_id,
            sortable: true
        },
        {
            name: 'Sub Equipment',
            selector: row => row.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department_id',
                    "options": [
                        "Agency",
                        "Logistics"
                    ],
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Area',
                    name: 'area_id',
                    "options": [
                        "Ship",
                        "Shore"
                    ],
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Facility',
                    name: 'facility_id',
                    "options": [
                        "Tester",
                        "Crew"
                    ],
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Equipment',
                    name: 'equipment_id',
                    "options": [
                        "Anchor",
                        "Hull"
                    ],
                    // "required": true
                },
                {
                    type: 'text',
                    label: 'Sub Equipment',
                    name: 'name',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": 'Logistics',
        "area": 'Ship',
        "facility": 'Tester',
        "equipment": 'Anchor',
        "subEquipment": "Chain"
    },{
        "department": 'Agency',
        "area": 'Shore',
        "facility": 'Crew',
        "equipment": 'Hull',
        "subEquipment": "Compass"
    },{
        "department": 'Logistics',
        "area": 'Ship',
        "facility": 'Tester',
        "equipment": 'Anchor',
        "subEquipment": "Chain"
    },{
        "department": 'Agency',
        "area": 'Shore',
        "facility": 'Crew',
        "equipment": 'Hull',
        "subEquipment": "Compass"
    }]
}
