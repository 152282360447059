import React, { useMemo } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ActionMenuItem
} from "material-react-table";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateSelect from '../dateSelect/dateSelect';
import { useDispatch } from "react-redux";
import { Edit, Delete, Visibility } from '@mui/icons-material';
import { setSnackMsg } from "../../redux/actions/common";
const data = [
  {
    sno: 87302334,
    dateofPurchase: "22-Jun-2024",
    make: "Order",
    poNumber:"13Oct2024",
    invoiceNumber: "12940245",
    date: "22-Jun-2024",
    warrentyStart: "23-Jun-2024",
    warrentyEnd:"02-Oct-2024",
    warrentyPeriod: "2Year",
    expiryNotification: "Testing",
    vendor: "Amazon",
    contactName:"Sundhar",
    contactNumber: "89234932032",
    contactmail: "sundhar@yahoo.com ",
  },
  {
    sno: 87302334,
    dateofPurchase: "22-Jun-2024",
    make: "Order",
    poNumber:"13Oct2024",
    invoiceNumber: "12940245",
    date: "22-Jun-2024",
    warrentyStart: "23-Jun-2024",
    warrentyEnd:"02-Oct-2024",
    warrentyPeriod: "2Year",
    expiryNotification: "Testing",
    vendor: "Amazon",
    contactName:"Sundhar",
    contactNumber: "89234932032",
    contactmail: "sundhar@yahoo.com ",
  }
];

export default function Tableworkorder({setOpenDialog}) {
  const dispatch = useDispatch();

  const handleView = (rowData) => {
    setOpenDialog({
      formType: 'view',
      ...rowData
    });
    console.log('View ---> ', rowData);

  };
  const handleEdit = (rowData) => {
    setOpenDialog({
      formType: 'edit',
      ...rowData
    });
    console.log('Edit ---> ',rowData);

  };
  const handleDelete = (rowData) => {
    console.log('Delete ---> ',rowData);
    dispatch(setSnackMsg({
      open: true, msg: 'Deleted Successfully'
    }));
  };
const defaultFormData = {
    orderNumber: '',
    orderType: '',
    shortJobDescription: '',
    detailedJobDescription: '',
    area: '',
    planFacility: '',
    equipment: '',
    subEquipment: '',
    deviceInstrument: '',
    tag: '',
    jobPriority: '',
    createdBy: '',
    reportedBy: '',
    assignedTo: '',
    orderFrom: null,
    orderTo: null,
    formType: 'add'
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.sno, 
        id: "sno",
        header: "S.NO",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.dateofPurchase, 
        id: "dateofPurchase",
        header: "Date of Purchase",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.make, 
        id: "make",
        header: "Make",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.poNumber, 
        id: "poNumber",
        header: "PO Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.invoiceNumber, 
        id: "invoiceNumber",
        header: "Invoice Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.date, 
        id: "date",
        header: "Date",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.warrentyStart, 
        id: "warrentyStart",
        header: "Warrenty  Start",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.warrentyEnd, 
        id: "warrentyEnd",
        header: "Warrenty End",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.warrentyPeriod, 
        id: "warrentyPeriod",
        header: "Warrenty Period",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.expiryNotification, 
        id: "expiryNotification",
        header: "Expiry Notification",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.vendor, 
        id: "vendor",
        header: "Vendor/Supplier",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.contactName, 
        id: "contactName",
        header: "Contact Name",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.contactNumber, 
        id: "contactNumber",
        header: "Contact Number",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      },
      {
        accessorFn: (row) => row.contactmail, 
        id: "contactmail",
        header: "Contact E-mail",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span> 
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    data,
    columns,
    renderTopToolbarCustomActions: () => (<>
      <Box >
        <Grid className='bg-slate-100 '>

          <Button
            variant="contained" color="success"
            onClick={() => {
              setOpenDialog(defaultFormData)
            }}
          >+ Add</Button>
          <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
            Export
          </Button>
          <Button variant="contained" className='bg-violet-500' >
            Import
          </Button>
          <DateSelect></DateSelect>

        </Grid>
      </Box>
    </>),
    enableStickyHeader: true,
    enableColumnPinning: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    initialState: { columnPinning: { right: ['mrt-row-actions'] } },
    renderRowActionMenuItems: ({ closeMenu, row, table }) => [
      <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
        icon={<Visibility />}
        key="view"
        label="View"
        table={table}
        onClick={() => {
          handleView(row?.original);
          closeMenu();
        }} />,
      <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
        icon={<Edit />}
        key="edit"
        label="Edit"
        table={table}
        onClick={() => {
          handleEdit(row?.original);
          closeMenu();
        }} />,
      <MRT_ActionMenuItem
        icon={<Delete />}
        key="delete"
        label="Delete"
        table={table}
        onClick={() => {
          handleDelete(row?.original);
          closeMenu();
        }} />,
    ],
  });

  return <MaterialReactTable table={table} />;
}
