import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './warrenty.css';
import HeaderMMS from '../header/header';
import MenuMMS from '../menu/menu';
import Forms from './form';
import Tableworkorder from './table';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Styled Dialog Component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openNewDialog, setOpenNewDialog] = React.useState(false); // State for the new dialog

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleNewDialogOpen = () => {
    setOpenNewDialog(true);
  };

  const handleNewDialogClose = () => {
    setOpenNewDialog(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const headerProps = {
    open, handleDrawerOpen
  };
  
  const menuProps = {
    setOpen, open
  };
  
  const tableProps = {
    setOpenDialog
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  const [value, setValue] = React.useState(0);

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <HeaderMMS {...headerProps} />
      <MenuMMS {...menuProps} />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }} className="bg-slate-100 overflow-hidden mms-cnt-main">
        <Grid className="bg-slate-100">
          <DrawerHeader />
          <div className="rounded p-3 text-lg text-left font-semibold">
            <h2>Warranty Manager</h2>
          </div>

          <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={tabhandleChange} aria-label="basic tabs example" className='mms-tabs overflow-y-auto'>
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Inwarranty" {...a11yProps(1)} />
          
          <Tab label="Soon to expire" {...a11yProps(2)} />
          <Tab label="Out of warranty" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box className="bg-slate-100 mms-table-sec">
            <Tableworkorder {...tableProps} />
          </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      Inwarranty
      </CustomTabPanel>
     
      <CustomTabPanel value={value} index={2}>
      Soon to expire
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      Out of warranty
      </CustomTabPanel>
      
    </Box>

          {/* First Dialog */}
          <BootstrapDialog
            className="mms-full-dig"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openDialog}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="bg-sky-600 text-white">
              Warranty Manager
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Forms handleClose={handleClose} formDataObj={openDialog} />
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>

          {/* New Dialog */}
          <BootstrapDialog
            className="mms-full-dig"
            onClose={handleNewDialogClose}
            aria-labelledby="customized-dialog-title"
            open={openNewDialog}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="bg-sky-600 text-white">
             View and Histrory
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleNewDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              {/* Add content for the new dialog here */}
             
             <div className='mms-vies-list'>
                <table>
                    <tr>
                        <td><strong>Number</strong></td>
                        <td><span>123444</span></td>
                    </tr>
                    <tr>
                        <td><strong>State</strong></td>
                        <td><span>Waiting</span></td>
                    </tr>
                    <tr>
                        <td><strong>Created</strong></td>
                        <td><span>7 Days Ago</span></td>
                    </tr>
                    <tr>
                        <td><strong>Updated</strong></td>
                        <td><span>A Day Ago</span></td>
                    </tr>
                    <tr>
                        <td><strong>Request for</strong></td>
                        <td><span>Vijay</span></td>
                    </tr>
                    <tr>
                        <td><strong>Software Category</strong></td>
                        <td><span>Adobe System</span></td>
                    </tr>
                    <tr>
                        <td><strong>Software Model</strong></td>
                        <td><span>123444</span></td>
                    </tr>
                    <tr>
                        <td><strong>License Availability Status</strong></td>
                        <td><span>No Stock</span></td>
                    </tr>
                    <tr>
                        <td><strong>Price</strong></td>
                        <td><span>Undefined</span></td>
                    </tr>
                </table>
             </div>
             <div className='mms-history-cnt'>
             <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            History
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
       <div className='mms-history-list-view'>
        <div className='mms-history-li'>
            <div className='history-updated-name'>
                <h2><strong>Updated By : </strong><span>Vijay</span></h2>
            </div>
            <div className='history-list-details'>
                <ul>
                    <li><label>Action : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Action Description : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Status  :</label> <span>Closed</span></li>
                </ul>
            </div>
        </div>
        <div className='mms-history-li'>
            <div className='history-updated-name'>
                <h2><strong>Updated By : </strong><span>Vijay</span></h2>
            </div>
            <div className='history-list-details'>
                <ul>
                    <li><label>Action : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Action Description : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Status  :</label> <span>Closed</span></li>
                </ul>
            </div>
        </div>
        <div className='mms-history-li'>
            <div className='history-updated-name'>
                <h2><strong>Updated By : </strong><span>Vijay</span></h2>
            </div>
            <div className='history-list-details'>
                <ul>
                    <li><label>Action : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Action Description : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Status  :</label> <span>Closed</span></li>
                </ul>
            </div>
        </div>
        <div className='mms-history-li'>
            <div className='history-updated-name'>
                <h2><strong>Updated By : </strong><span>Vijay</span></h2>
            </div>
            <div className='history-list-details'>
                <ul>
                    <li><label>Action : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Action Description : </label> <span>Ticket Status Updated</span></li>
                    <li><label>Status  :</label> <span>Closed</span></li>
                </ul>
            </div>
        </div>
       </div>
        </AccordionDetails>
      </Accordion>
     
      
 
    </div>
             </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNewDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>

         

          {/* Button to Open the New Dialog */}
          <Button variant="contained" color="primary" onClick={handleNewDialogOpen}>
          View and Histrory
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
