

/** For APIAlert */
export const setApiAlert = (payload) => {
    return {
        type: "SET_API_ALERT",
        payload
    }
};
/** For Menu Pin */
export const setPinMenu = (payload) => {
    return {
        type: "SET_PIN_MENU",
        payload
    }
};
/** For Full screen loader */
export const setFullLoad = (payload) => {
    return {
        type: "SET_FULL_LOAD",
        payload
    }
};
/** For Snack Bar msg details */
export const setSnackMsg = (payload) => {
    return {
        type: "SET_SNACK_MSG",
        payload
    }
};
