
export default function common(state = {}, { type, payload }) {
    switch (type) {
        case "SET_API_ALERT":
            return { ...state, apiAlert: payload };
        case "SET_PIN_MENU":
            return { ...state, pinMenu: payload };
        case "SET_FULL_LOAD":
            return { ...state, fullLoad: payload };
        case "SET_SNACK_MSG":
            return { ...state, snackMsg: payload };
        default:
            return state;
    }

};
