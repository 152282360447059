import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
  Handyman as HandymanIcon,
  Dashboard as DashboardIcon,
  FileOpen as FileOpenIcon,
  Email as EmailIcon,
  Backup as BackupIcon,
  Assessment as AssessmentIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
  Camera as CameraIcon,
  Build as BuildIcon,
  Task as TaskIcon,
  Pin as PinIcon,
  PushPinOutlined as UnpinIcon,
  Api as ApiIcon,
  AutoGraph as AutoGraphIcon,
  CompassCalibration as CompassCalibrationIcon,
  SettingsApplications as SettingsApplicationsIcon
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPinMenu } from "../../redux/selector";
import { setPinMenu } from "../../redux/actions/common";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconLogs,IconFileCode,IconMailCode,IconDatabaseExport ,IconSettings,IconTool,IconTools,IconReport,
IconClipboardData,IconSettings2,IconOutbound,IconPencilShare,IconAdjustmentsAlt,IconTruckDelivery,IconAward,IconVirusSearch
} from '@tabler/icons-react';

const drawerWidth = 240;

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function MenuMMS({ setOpen, open }) {
  const pinned = useSelector(getPinMenu) || false;
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [submenuState, setSubmenuState] = useState({});

  const handleSubmenuToggle = (menuName) => {
    setSubmenuState((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
  };

  const menuItems = [
    { 
      name: 'Dashboard', icon: <IconSettings />, path: '/dashboard' 
    },
    { 
      name: 'Daily Logs', icon: <IconLogs />, path: '/logs' 
    },
    { 
      name: 'Work Order', icon: <IconTool />, path: '/workorder',
      submenu: [
        { name: 'Received Work Order', path: '/workorder/receivedworkorder' },
        { name: 'Issued Work Order', path: '/workorder/issuedworkorder' },
        { name: 'Scheduled Work Order', path: '/workorder/scheduledworkorder' },
      ],
    },
    { 
      name: 'Warrenty', icon: <IconAward />, path: '/warrenty' 
    },
    { 
      name: 'Maintenance', icon: <IconTools />, path: '/maintenance' 
    },
    { 
      name: 'Calibration Order', icon: <IconTruckDelivery />, path: '/calibrationOrder' 
    },
    { 
      name: 'Critical Spare List', icon: <IconVirusSearch />, path: '/criticalSpareList' 
    },
    { 
      name: 'Task', icon: <IconFileCode />, path: '/task' 
    },
    { 
      name: 'Email', icon: <IconMailCode />, path: '/email' 
    },
    { 
      name: 'DB Backup', icon: <IconDatabaseExport />, path: '/backup' 
    },
    { 
      name: 'Configure', icon: <IconAdjustmentsAlt />, path: '/configure' 
    },
    { 
      name: 'Near Miss', icon: <IconOutbound />, path: '/nearmiss' 
    },
    { 
      name: 'Reports', icon: <IconReport />, path: '/reports',
      submenu: [
        { name: 'Workorder Reports', path: '/reports/workorderReports' },
        { name: 'Warrenty Reports', path: '/reports/warrentyReports' }
        
      ],
    },
    { 
      name: 'ERT', icon: <IconClipboardData />, path: '/ert',
    },
    { 
      name: 'Settings', icon: <IconSettings2 />, path: '/settings',
      submenu: [
        { name: 'User Settings', path: '/settings/user' },
      ],
    }
  ];

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleDrawerToggle = () => {
    if (pinned) {
      dispatch(setPinMenu(!pinned));
      setOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const handlePinToggle = () => {
    setOpen(true);
    dispatch(setPinMenu(!pinned));
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  return (
    <Drawer variant="permanent" open={pinned || open} className='bg-sky-500 mms-left-menu'>
      <DrawerHeader className='bg-sky-500'>
        <IconButton onClick={handleDrawerToggle}>
          {pinned ? <UnpinIcon /> : <MenuIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className='bg-sky-500 text-white'>
        {menuItems.map((menuItem) => (
          <React.Fragment key={menuItem.name}>
            <ListItem disablePadding>
              <BootstrapTooltip title={menuItem.name} placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: (pinned || open) ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => {
                    menuItem.submenu ? handleSubmenuToggle(menuItem.name) : handleMenuClick(menuItem.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: (pinned || open) ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={menuItem.name} sx={{ opacity: (pinned || open) ? 1 : 0 }} />
                  {menuItem.submenu && (
                    submenuState[menuItem.name] ? <ExpandLess /> : <ExpandMore />
                  )}
                </ListItemButton>
              </BootstrapTooltip>
            </ListItem>

            {menuItem.submenu && (
              <Collapse in={submenuState[menuItem.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuItem.submenu.map((subItem) => (
                    <BootstrapTooltip key={subItem.name} title={subItem.name} placement="right">
                      <ListItemButton 
                        sx={{ pl: 4 }} 
                        onClick={() => handleMenuClick(subItem.path)}
                      >
                      
                        <ListItemText primary={subItem.name} />
                      </ListItemButton>
                    </BootstrapTooltip>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
      <List>
        <ListItem button onClick={handlePinToggle}>
          <ListItemIcon>{pinned ? <UnpinIcon /> : <PinIcon />}</ListItemIcon>
          <ListItemText primary={pinned ? 'Unpin Menu' : 'Pin Menu'} />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MenuMMS;
