
export const baseUrl = 'https://api-fluid.quixlab.in';
export const headers = {
    "Content-type": "application/json; charset=UTF-8"
};
export const workOrderDefaultVal = {
    formType: 'add',
    orderNumber: '',
    orderType: '',
    shortJobDescription: '',
    detailedJobDescription: '',
    area: '',
    planFacility: '',
    equipment: '',
    subEquipment: '',
    deviceInstrument: '',
    tag: '',
    jobPriority: '',
    createdBy: '',
    reportedBy: '',
    assignedTo: '',
    orderFrom: null,
    orderTo: null
};

export const defaultTableVal = '-';
