
import { baseUrl } from "../../constants";
import { getDDDeptCall } from "../../redux/actions/dropDown";
import { setFullLoad } from "../../redux/actions/common";

export default {
    "formTitle": "Add Employee",
    "tableTitle": "Employee List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(setFullLoad(true));
            const dropDownArr = [
                getDDDeptCall,
            ];
            setTimeout(()=>{
                dispatch(setFullLoad(false));
            }, 500);
            dropDownArr.forEach((func,) => {
                    dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/mms/employee/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/employee`,
        edit: `${baseUrl}/api/mms/employee/edit`,
        delete: `${baseUrl}/api/mms/employee/remove`
    },
    "tableCol": [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Employee Code',
            selector: row => row.empCode,
            sortable: true
        },
        {
            name: 'Department',
            selector: row => row.department_id,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.empEmail,
            sortable: true
        },
        {
            name: 'Phone',
            selector: row => row.empPhone,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'text',
                    label: 'Name',
                    name: 'name',
                    // "required": true
                },
                {
                    type: 'text',
                    label: 'Employee Code',
                    name: 'empCode',
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'email',
                    label: 'Email',
                    name: 'empEmail',
                    // "required": true
                },
                {
                    type: 'number',
                    label: 'Phone',
                    name: 'empPhone'
                }
            ]
        }
    ],
    "mockData": [{
        "name": "Kumar",
        "empCode": "code007",
        "department": 1,
        "empEmail": "asds@sf.com",
        "empPhone": 23123
    }]
}