import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useSelector, useDispatch } from "react-redux";

import { getSnackMsg } from "../../redux/selector";
import { setSnackMsg } from '../../redux/actions/common';

export default function SnackBarMsg() {
    const {
        onClose,
        open = false,
        error = false,
        msg = '-',
        type = 'success',
        autoHideDuration = 5000
    } = useSelector(getSnackMsg) || {};
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setSnackMsg({
          open: false, msg: '-'
        }));
        onClose?.();
    };

    return (
        <Snackbar 
        open={open} 
        autoHideDuration={autoHideDuration} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={error ? 'error' : type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {msg}
            </Alert>
        </Snackbar>
    );
}
