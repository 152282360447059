
const initialState = {
    orderType: [
        { id: 1, name: "Internal" },
        { id: 2, name: "External" }
    ]
};

export default function dropDown(state = initialState, { type, payload }) {
    switch (type) {
        case "SET_DD_DEPT":
            return { ...state, department_id: payload };
        case "SET_DD_AREA":
            return { ...state, area_id: payload };
        case "SET_DD_FACILITY":
            return { ...state, facility_id: payload };
        case "SET_DD_EQU":
            return { ...state, equipment_id: payload };
        case "SET_DD_SUB_EQU":
            return { ...state, sub_equipment_id: payload };
        case "SET_DD_DEVICE":
            return { ...state, device_id: payload };
        case "SET_DD_EMP":
            return { ...state, employee_id: payload };
        case "SET_DD_JOB_N":
            return { ...state, job_nature_id: payload };
        case "SET_DD_JOB_P":
            return { ...state, job_priority_id: payload };
        case "SET_DD_WORK_PERMIT":
            return { ...state, work_permit_id: payload };
        case "SET_DD_VENDOR":
            return { ...state, vendor_id: payload };
        case "SET_DD_TASK":
            return { ...state, task_id: payload };
        case "SET_DD_ORDER_TYPE":
            return { ...state, orderType: payload };
        default:
            return state;
    }

};
