import logo from './logo.svg';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import './App.css';
import Login from './login/login';
import IssuedWorkorder from './components/workorder/issuedWorkorder';
import ReceivedWorkorder from './components/workorder/receivedWorkorder';
import ScheduledWorkorder from './components/workorder/scheduledWorkorder';
import Dashboard from './components/dashboard';
import Warrenty from './components/warrenty/warrenty';
import Maintenance from './components/maintenance/maintenance'
import Configure from './pages/configure/configure';
import CriticalSpareList from './components/criticalSpareList/criticalSpareList';
import Settings from './components/settings/settings';
import Task from './components/task/task';
import Logs from './components/logs/logs';
import Email from './components/email/email';
import ERT from './components/ert/ert';
import Reports from './components/reports/reports';
import CalibrationOrder from './components/calibrationOrder/calibrationOrder';
import NearMiss from './components/nearMiss/nearmiss';
import FullScreenLoader from './components/fullScreenLoader/fullScreenLoader';
import SnackBarMsg from './components/snackBarMsg/snackBarMsg';
import WarrentyReports from './components/reports/warrentyReports'
import WorkorderReports from './components/reports/workorderReport'

function App() {
  return (
    <div className="App">
      <SnackBarMsg />
      <FullScreenLoader />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/workorder/issuedWorkorder" element={<IssuedWorkorder />} />
          <Route path="/workorder/receivedWorkorder" element={<ReceivedWorkorder />} />
          <Route path="/workorder/scheduledWorkorder" element={<ScheduledWorkorder />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/warrenty" element={<Warrenty />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/configure" element={<Configure />} />
          <Route path="/criticalSpareList" element={<CriticalSpareList />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/task" element={<Task />} />
          <Route path='/logs' element={<Logs />} />
          <Route path='/email' element={<Email />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/ert' element={<ERT />} />
          <Route path='/nearmiss' element={<NearMiss />} />
          <Route path='/calibrationOrder' element={<CalibrationOrder />} />
          <Route path='/reports/warrentyReports' element={<WarrentyReports />} />
          <Route path='/reports/workorderReports' element={<WorkorderReports />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
