
import { baseUrl } from "../../constants";
import { getDDDeptCall, getDDAreaCall } from "../../redux/actions/dropDown";
import { setFullLoad } from "../../redux/actions/common";

export default {
    "formTitle": "Add Plan / Facility",
    "tableTitle": "Plan / Facility List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(setFullLoad(true));
            const dropDownArr = [
                getDDDeptCall, getDDAreaCall
            ];
            setTimeout(()=>{
                dispatch(setFullLoad(false));
            }, 500);
            dropDownArr.forEach((func,) => {
                    dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/mms/facility/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/facility`,
        edit: `${baseUrl}/api/mms/facility/edit`,
        delete: `${baseUrl}/api/mms/facility/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.department_id,
            sortable: true
        },
        {
            name: 'Area',
            selector: row => row.area_id,
            sortable: true
        },
        {
            name: 'Facility',
            selector: row => row.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Area',
                    name: 'area_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'text',
                    label: 'Facility',
                    name: 'name',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": 1,
        "area": 1,
        "facility": "safds"
    }]
}