
import { baseUrl } from "../../constants";
import { getDDDeptCall, getDDAreaCall, getDDFacilityCall } from "../../redux/actions/dropDown";
import { setFullLoad } from "../../redux/actions/common";

export default {
    "formTitle": "Add Equipment",
    "tableTitle": "Equipment List",
    "apiDetails": {
        startUp: (dispatch) => {
            dispatch(setFullLoad(true));
            const dropDownArr = [
                getDDDeptCall, getDDAreaCall, getDDFacilityCall
            ];
            setTimeout(()=>{
                dispatch(setFullLoad(false));
            }, 500);
            dropDownArr.forEach((func,) => {
                    dispatch(func());
            });
        },
        list: {
            url: `${baseUrl}/api/mms/equipment/list`,
            payload: {
                "search": "",
                "offset": 0,
                "limit": 50,
                "sort": "DESC",
                "column": "id"
            }
        },
        add: `${baseUrl}/api/mms/equipment`,
        edit: `${baseUrl}/api/mms/equipment/edit`,
        delete: `${baseUrl}/api/mms/equipment/remove`
    },
    "tableCol": [
        {
            name: 'Department',
            selector: row => row.department_id,
            sortable: true
        },
        {
            name: 'Area',
            selector: row => row.area_id,
            sortable: true
        },
        {
            name: 'Facility',
            selector: row => row.facility_id,
            sortable: true
        },
        {
            name: 'Equipment',
            selector: row => row.name,
            sortable: true
        }
    ],
    "formData": [
        {
            "formType": "flex",
            "formFields": [
                {
                    type: 'select',
                    label: 'Department',
                    name: 'department_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Area',
                    name: 'area_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'select',
                    label: 'Facility',
                    name: 'facility_id',
                    "options": [],
                    // "required": true
                },
                {
                    type: 'text',
                    label: 'Equipment',
                    name: 'name',
                    "required": true
                }
            ]
        }
    ],
    "mockData": [{
        "department": 1,
        "area": 1,
        "facility": 1,
        "equipment": "safds"
    }]
}