import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import HeaderMMS from './header/header';
import MenuMMS from './menu/menu';
import './workorder/workorder.css';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';

const settings = {
  width: 200,
  height: 200,
  value: 60,
};

const additionalData = [
  { label: 'Total', value: 400 },
  { label: 'In Warrenty', value: 300 },
  { label: 'Out of warrenty', value: 300 },
  { label: 'Soon to expire', value: 200 },
];

const dataTasks = [
  { label: 'Total Task', value: 400 },
  { label: 'Completed Task', value: 300 },
  { label: 'Pending Task', value: 300 },
];



const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

const warantySize = {
  width: 650,
  height: 400,
};

const orderSize = {
  width: 700,
  height: 450,
};

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}
export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const headerProps = {
    open,
    handleDrawerOpen,
  };

  const menuProps = {
    setOpen,
    open,
  };
  const data2 = [
    { label: 'Total PM order', value: 2400 },
    { label: 'Completed PM order', value: 4567 },
    { label: 'Due PM orders ', value: 1398 },
    { label: 'Soon to Due PM orders', value: 9800 },
    { label: 'Over Due PM Orders', value: 3908 },
  ];
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderMMS {...headerProps} />
      <MenuMMS {...menuProps} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="bg-slate-100 overflow-hidden">
        <Grid className="bg-slate-100">
          <DrawerHeader />
          <div className="rounded p-3 text-lg text-left font-semibold">
            <h2>Dashboard</h2>
          </div>
          <div className="">
            <div className="text-left bg-white p-3 m-2 border-solid border rounded">
              <div className="border-slate-300 rounded-md">
                <h3 className="border-b-2 p-3">Work Order</h3>
              </div>
              <div className="m-5 flex mb-2">
                <div className="text-center pt-2">
                  <BarChart
                    xAxis={[
                      {
                        scaleType: 'band',
                        data: [
                          'Total Work orders',
                          'Open orders',
                          'Completed order',
                          'Pending orders',
                        ],
                      },
                    ]}
                    series={[
                      { data: [4, 3, 5, 3] },
                      { data: [1, 6, 3, 9] },
                      { data: [2, 5, 6, 1] },
                      { data: [2, 5, 6, 7] },
                    ]}
                    width={600}
                    height={200}
                  />
                  {/* <Gauge width={200} height={200} value={60} startAngle={-90} endAngle={90} /> */}
                  
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-left bg-white p-3 m-2 border-solid border rounded ">
              <div className="border-slate-300 rounded-md">
                <h3 className="border-b-2 p-3">PM Work Order</h3>
              </div>
              <div className="m-5 flex mb-2">
                <div className="w-1/2 pt-2">
                <Gauge width={300} height={300} value={50} valueMin={10} valueMax={60} />
                </div>
                <div className="w-1/2 pt-2"></div>
              </div>
            </div>
            <div className="text-left bg-white p-3 m-2 border-solid border rounded ">
              <div className="border-slate-300 rounded-md">
                <h3 className="border-b-2 p-3">Warranty Manager</h3>
              </div>
              <div className="w-1/2 pt-2">
              <Gauge
  {...settings}
  cornerRadius="50%"
  sx={(theme) => ({
    [`& .${gaugeClasses.valueText}`]: {
      fontSize: 40,
    },
    [`& .${gaugeClasses.valueArc}`]: {
      fill: '#52b202',
    },
    [`& .${gaugeClasses.referenceArc}`]: {
      fill: theme.palette.text.disabled,
    },
  })}
/>
              <PieChart
                    series={[
                      {
                        arcLabel: (item) => `${item.label} (${item.value})`,
                        arcLabelMinAngle: 45,
                        data: additionalData,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                      },
                    }}
                    {...orderSize}
                  />
                  
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-left bg-white p-3 m-2 border-solid border rounded">
              <div className="border-slate-300 rounded-md">
                <h3 className="border-b-2 p-3">Task</h3>
              </div>
              <div className="m-5 flex mb-2">
                <div className="pt-2">
                
                  <GaugeContainer
  width={200}
  height={200}
  startAngle={-110}
  endAngle={110}
  value={30}
>
  <GaugeReferenceArc />
  <GaugeValueArc />
  <GaugePointer />
</GaugeContainer>
                </div>
              </div>
            </div>
           
          </div>
        </Grid>
      </Box>
    </Box>
  );
}

