import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from "react-redux";
import { getFullLoad } from "../../redux/selector";

export default function FullScreenLoader() {
    const open = useSelector(getFullLoad) || false;

    return (
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: 1500 })}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
