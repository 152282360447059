import React, { useEffect, useMemo, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ActionMenuItem
} from "material-react-table";
import { Edit, Delete, Visibility } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";

import DateSelect from '../dateSelect/dateSelect';
import { defaultTableVal, workOrderDefaultVal } from "../../constants";
import { getFlatRowData } from "../../utils";
const data = [
  {
    orderNumber: 93242042,
    orderType: "Container",
    orderFrom: "20-Jun-2024",
    orderTo: "19-Jul-2024",
    shortJobDescription: "Test Message",
    detailedJobDescription: "Test Message",
    area: "IT",
    planFacility: "Test",
    equipment: "loram",
    subEquipment: "Container",
    deviceInstrument: "Testing",
    tag: "Loram",
    jobPriority: "High",
    createdBy: "David ",
    reportedBy: "Sam",
    assignedTo: "Kunal",
  },
  {
    orderNumber: 87302334,
    orderType: "Request",
    orderFrom: "17-May-2024",
    orderTo: "13-Oct-2024",
    shortJobDescription: "Test Message",
    detailedJobDescription: "Test Message",
    area: "Mach",
    planFacility: "Facility",
    equipment: "loram",
    subEquipment: "Container",
    deviceInstrument: "Testing",
    tag: "Loram",
    jobPriority: "High",
    createdBy: "David ",
    reportedBy: "Sam",
    assignedTo: "Kunal",
  },
  {
    orderNumber: 87302334,
    orderType: "Request",
    orderFrom: "17-May-2024",
    orderTo: "13-Oct-2024",
    shortJobDescription: "Test Message",
    detailedJobDescription: "Test Message",
    area: "Mach",
    planFacility: "Facility",
    equipment: "loram",
    subEquipment: "Container",
    deviceInstrument: "Testing",
    tag: "Loram",
    jobPriority: "High",
    createdBy: "David ",
    reportedBy: "Sam",
    assignedTo: "Kunal",
  }
];

export default function Tableworkorder({ setOpenDialog, tableData, deleteTableData }) {
  const dispatch = useDispatch();

  const handleView = (rowData) => {
    const flatRowData = getFlatRowData(rowData);
    setOpenDialog({
      formType: 'view',
      ...flatRowData
    });
    console.log('View ---> ', rowData);

  };
  const handleEdit = (rowData) => {
    const flatRowData = getFlatRowData(rowData);
    setOpenDialog({
      formType: 'edit',
      ...flatRowData
    });
    console.log('Edit ---> ', rowData);

  };
  const handleDelete = (rowData) => {
    console.log('Delete ---> ', rowData);
    deleteTableData?.(rowData.id);
  };

  const defaultFormData = {
    slno: '',
    dateofpurchase: null,
    make: '',
    poNumber: '',
    invoiceNumber: '',
    date: '',
    warrentystart: '',
    warrentyend: '',
    warrentyPeriod: '',
    expiryNotification: '',
    vendorSupplier: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    dateinvoice: null,
    dateinvoicenum: null,
  };

  const renderCell = ({ renderedCellValue = defaultTableVal }) => <span>{
    typeof renderedCellValue === 'object' ? (renderedCellValue?.name || defaultTableVal) : (renderedCellValue || defaultTableVal)
  }</span>;

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.orderNumber,
        id: "orderNumber",
        header: "Order Number",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.orderType,
        id: "orderType",
        header: "Order Type",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.orderFrom,
        id: "orderFrom",
        header: "Order From",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.orderTo,
        id: "orderTo",
        header: "Order To",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.shortJobDescription,
        id: "shortJobDescription",
        header: "Short Job Description ",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.detailedJobDescription,
        id: "detailedJobDescription",
        header: "Detailed Job Description",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.area,
        id: "area",
        header: "Area",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.planFacility,
        id: "planFacility",
        header: "Plan / Facility",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.equipment,
        id: "equipment",
        header: "Equipment",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.subEquipment,
        id: "subEquipment",
        header: "Sub -Equipment",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.deviceInstrument,
        id: "deviceInstrument",
        header: "Device / Instrument",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.tag,
        id: "tag",
        header: "Tag",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.jobPriority,
        id: "jobPriority",
        header: "Job Priority",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.createdBy,
        id: "createdBy",
        header: "Created By",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.reportedBy,
        id: "reportedBy",
        header: "Reported By",
        Cell: renderCell
      },
      {
        accessorFn: (row) => row.assignedTo,
        id: "assignedTo",
        header: "Assigned To",
        Cell: renderCell
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    data: tableData,
    columns,
    renderTopToolbarCustomActions: () => (<>
      <Box >
        <Grid className='bg-slate-100 '>

          <Button
            variant="contained" color="success"
            onClick={() => {
              setOpenDialog({
                formType: 'add',
                ...workOrderDefaultVal
              })
            }}
          >+ Add</Button>
          <Button variant="contained" className='bg-amber-500 mx-0.5 mlr-5' >
            Export
          </Button>
          <Button variant="contained" className='bg-violet-500' >
            Import
          </Button>
          <DateSelect></DateSelect>

        </Grid>
      </Box>
    </>),
    enableStickyHeader: true,
    enableColumnPinning: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    initialState: { columnPinning: { right: ['mrt-row-actions'] } },
    renderRowActionMenuItems: ({ closeMenu, row, table }) => [
      <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
        icon={<Visibility />}
        key="view"
        label="View"
        table={table}
        onClick={() => {
          handleView(row?.original);
          closeMenu();
        }} />,
      <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
        icon={<Edit />}
        key="edit"
        label="Edit"
        table={table}
        onClick={() => {
          handleEdit(row?.original);
          closeMenu();
        }} />,
      <MRT_ActionMenuItem
        icon={<Delete />}
        key="delete"
        label="Delete"
        table={table}
        onClick={() => {
          handleDelete(row?.original);
          closeMenu();
        }} />,
    ],
  });

  return <MaterialReactTable table={table} />;
}
